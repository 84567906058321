import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'
import { ArticlesByMonth, Sitemap } from '@jelly/templates'
import { groupContentByDate } from '@jelly/utils'

const META = {
	title: `Product and Service Reviews â€“ business.com`,
	description: `Find product and service reviews that can help grow your business`,
	robots: 'index, follow',
	canonical: `/sitemap/buying-guides/`,
}

export default function BuyingGuides({ data }) {
	const groupedCategoriesByDate = groupContentByDate(data.categories)

	return (
		<Sitemap meta={META}>
			<ArticlesByMonth data={groupedCategoriesByDate} />
		</Sitemap>
	)
}

BuyingGuides.propTypes = { data: object, pageContext: object }

export const GatsbyQuery = graphql`
	query categories {
		categories(sort: { displayModified: DESC }) {
			displayModified
			path
			title
		}
	}
`
